<template>
    <table class="col-12">
        <thead>
            <tr>
                <th class="width-25">Просмотр</th>
                <th class="">CID площадки</th>
                <th class="width-10">Дата создания</th>
                <th class="width-25">Фото</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <AppealReportTableElement 
            v-for="appeal in results" 
            :key="appeal.id"
            :id = "appeal.id"
            :cid = "appeal.cid"
            :image = "appeal.image"
            :created_at="appeal.created_at"
            />
        </tbody>
    </table>
</template>
<script>
    import AppealReportTableElement from "@/components/Reports/Appeal/Elements/AppealView/AppealReportTableElement.vue";

    export default {
        components: { AppealReportTableElement },
        props: ['results'],
    }
</script>