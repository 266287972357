<template>
    <tr>
        <td><router-link :to="{path: '/reports/appeal/' + protectionId + '/report/' + id}">Просмотреть отчёт</router-link></td>
        <td>{{ cid }}</td>
        <td>{{ format_date(created_at) }}</td>
        <td><img v-if="image != ''" :src="image" width="100px"></td>
        <td class="right"><router-link :to="{path: '/reports/appeal/' + protectionId + '/report/' + id}"><span class="icon-more-vertical"></span></router-link></td>
    </tr>
</template>

<script>
    import moment from 'moment'
    
    export default {
        data() {
            return {
                protectionId: this.$route.params.id
            }
        },
        props: ['id', 'cid', 'created_at', 'image'],
        methods: { 
            format_date(value){
                if (value) {
                    return moment(String(value)).format('DD.MM.YYYY')
                }
            },
        },
    }
</script>